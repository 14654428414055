import React from 'react';
import {
  Box, Drawer, DrawerContent, DrawerOverlay, DrawerCloseButton, List, ListItem,
  useDisclosure, useMediaQuery, IconButton, Image, VStack, Flex
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import Countdown from '../../components/CountDown';

function NavigationDrawer() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isDesktop] = useMediaQuery('(min-width: 768px)');
  const navigate = useNavigate();

  const items = [
    { text: 'Dashboard', path: '/dashboard' },
    { text: 'Ottelut', path: '/match' },
    { text: 'Pelaajat', path: '/players' },
    // Lisää muita navigointikohteita tähän
  ];

  // Päivitetty funktio, joka sulkee navigaation ja navigoi valittuun polkuun
  const handleItemClick = (path) => {
    onClose(); // Sulkee navigaation
    navigate(path); // Navigoi valittuun polkuun
  };

  const itemList = (
    <List spacing={4} width="100%" style={{ textAlign: 'center' }}>
      {items.map((item, index) => (
        <ListItem key={index} py="3" _hover={{ bg: 'mutedSageGreenDark' }} cursor="pointer" onClick={() => handleItemClick(item.path)}>
          {item.text}
        </ListItem>
      ))}
    </List>
  );

  return (
    <Flex>
      {isDesktop ? (
        <Box bg="antiqueWhite" minHeight="100vh" w="250px" top="0">
          <VStack spacing={4} alignItems="center" h="100%">
            <Image
              src="/logo.png"
              boxSize="150px"
              mt="4"
              mb="0"
            />
            {itemList}
            <Countdown />
          </VStack>
        </Box>
      ) : (
        <>
          <IconButton
            icon={<HamburgerIcon />}
            onClick={onOpen}
            position="fixed"
            zIndex="overlay"
            left={1}
            top={1}
            bg="antiqueWhite"
            _hover={{ bg: 'mutedSageGreenDark' }}
          />
          <Drawer placement="left" onClose={onClose} isOpen={isOpen}>
            <DrawerOverlay />
            <DrawerContent bg="antiqueWhite">
              <Flex justifyContent="center" mt="6">
                <Image
                  src="/logo.png" // Logon polku
                  boxSize="150px" // Säädä kokoa tarpeen mukaan
                />
              </Flex>
              <DrawerCloseButton />
              {itemList}
              <Countdown />
            </DrawerContent>
          </Drawer>
        </>
      )}
    </Flex>
  );
}

export default NavigationDrawer;
