import React, { useState, useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import AnimationWrapper from '../../components/AnimationWrapper';
import Loading from '../../components/Loading';
import TableComponent from '../../components/TableComponent'; // oletetaan että se on oletusvienti
import { BarChartComponent } from '../../components/BarChartComponent'; // oletetaan että se on oletusvienti
import { LineChartComponent } from '../../components/LineChartComponent'; // oletetaan että se on oletusvienti
import axios from 'axios';

function Dashboard() {
  const [playerData, setPlayerData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [lineChartData, setLineChartData] = useState([]);

  useEffect(() => {
    const fetchPlayerData = async () => {
      try {
        const playerDataResponse = await axios.get(`${process.env.REACT_APP_API_URL}/calculate_stats`);
        const dailyPointsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/calculate_daily_points`);
        setTimeout(() => {
          // Muunnetaan saatu data sopivaan muotoon ennen kuin asetetaan tilaan
          const transformedLineChartData = dailyPointsResponse.data.daily_points.map(day => ({
            date: day.date,
            ...day.player_points // Käytä suoraan pelaajien nimet sisältävää dataa
          }));

          // Asetetaan tilaan saatujen vastausten perusteella
          setPlayerData(playerDataResponse.data.players);
          setLineChartData(transformedLineChartData);
          setIsLoading(false);
        }, 400);
      } catch (error) {
        console.error('Error fetching player data:', error);
      }
    };

    fetchPlayerData();
  }, []);

  // Määritellään sarakkeet taulukolle
  const columns = [
    { Header: 'Nimi', accessor: 'name' },
    { Header: 'Pisteet', accessor: 'points' },
    { Header: 'Otteluita', accessor: 'match_count' },
    { Header: 'Voitot', accessor: 'win_count' },
    { Header: 'JA Voitot', accessor: 'overtime_win_count' },
    { Header: 'Tappiot', accessor: 'loss_count' },
    { Header: 'JA tappiot', accessor: 'overtime_loss_count' },
    { Header: 'Tehdyt maalit', accessor: 'goals_for' },
    { Header: 'Päästetyt maalit', accessor: 'goals_against' },
    { Header: 'Maaliero', accessor: 'goal_difference' }
  ];

  const sortedPlayerData = playerData.sort((a, b) => b.points - a.points);

  const views = [
    <TableComponent 
      title="Pistetaulukko"
      data={sortedPlayerData}
      columns={columns}/>,
    <BarChartComponent
      title="Pistepylväät" 
      data={sortedPlayerData}/>,
    <LineChartComponent
      title="Piste-aikasarja" 
      data={lineChartData}/>
  ];

  return (
    <Box>
      {isLoading ? (
        <Loading message="Ladataan tietoja..." />
      ) : (
      <AnimationWrapper views={views} />
    )}
    </Box>
  );
}

export default Dashboard;
