import React, { useState, useEffect } from 'react';
import {
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  Text,
  VStack,
  Flex
} from '@chakra-ui/react';
import TableComponent from '../../components/TableComponent';
import AnimationWrapper from '../../components/AnimationWrapper';
import Loading from '../../components/Loading';
import axios from 'axios';

const Players = () => {
  const [players, setPlayers] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [playerName, setPlayerName] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const fetchPlayers = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/players`);
      setTimeout(() => {
        setPlayers(response.data.players);
        setIsLoading(false);
      }, 400);
    } catch (error) {
      console.error('Virhe pelaajien hakemisessa:', error);
    }
  };

  useEffect(() => {
    fetchPlayers();
  }, [isOpen]);

  const handleOpenModalForEdit = (id) => {
    const playerToEdit = players.find(player => player.id === id);
    if (playerToEdit) {
      setSelectedPlayer(playerToEdit);
      setPlayerName(playerToEdit.name);
      onOpen();
    } else {
      console.error('Pelaajaa ei löytynyt id:llä', id);
    }
};

  const handleOpenModalForAdd = () => {
    setSelectedPlayer(null);
    setPlayerName(''); // Tyhjennä playerName uuden pelaajan lisäämistä varten
    onOpen();
  };

  const handleSaveChanges = async () => {
    const playerData = { name: playerName };

    if (selectedPlayer) {
      await axios.put(`${process.env.REACT_APP_API_URL}/players/${selectedPlayer.id}`, playerData);
    } else {
      await axios.post(`${process.env.REACT_APP_API_URL}/players`, playerData);
    }

    await fetchPlayers(); // Päivitä pelaajalista
    setPlayerName(''); // Tyhjennä playerName
    onClose();
  };

  const columns = [
    { Header: "Nimi", accessor: "name" }
  ];

  const formatDate = (date) => {
    if (!date) return '';
    return new Date(date).toLocaleDateString("fi-FI", {
      day: 'numeric', month: 'numeric', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit'
    });
  };

  const renderNicknameHistory = (history) => {
    if (!history) return null;
    try {
      const nicknames = JSON.parse(history);
      if (nicknames.length === 0) {
        return null; // Ei renderöidä, jos lista on tyhjä
      }
      return (
        <VStack spacing={2} align="start">
          <Text fontWeight="bold">Nickname Historia:</Text>
          {nicknames.map((nickname, index) => (
            <Text key={index}>{nickname}</Text>
          ))}
        </VStack>
      );
    } catch (error) {
      return <Text>Virhe nicknamen historiassa</Text>;
    }
  };

  return (
    <>
      {isLoading ? (
        <Loading message="Ladataan pelaajia..." />
      ) : (
      <AnimationWrapper views={[
        <TableComponent
          title="Pelaajat"
          data={players}
          columns={columns}
          onAddRow={handleOpenModalForAdd}
          onEditRow={(playerId) => handleOpenModalForEdit(playerId)}
        />
      ]} />
      )}
      <Modal isOpen={isOpen} onClose={() => { setPlayerName(''); onClose(); }}>
        <ModalOverlay />
        <ModalContent bg='antiqueWhite'>
          <ModalHeader>
            <Flex justifyContent="space-between" alignItems="center">
              <Text>{selectedPlayer ? 'Muokkaa pelaajaa' : 'Lisää uusi pelaaja'}</Text>
              <Flex direction="column" alignItems="flex-end" fontSize="10" fontStyle="italic" color='mutedSageGreenDark'>
                {selectedPlayer && (
                  <>
                    <Text>Luotu: {formatDate(selectedPlayer.created)}</Text>
                    <Text>Muokattu: {formatDate(selectedPlayer.last_modified)}</Text>
                  </>
                )}
              </Flex>
            </Flex>
          </ModalHeader>
          <ModalBody>
            <FormControl>
              <FormLabel>Nimi</FormLabel>
              <Input placeholder="Nimi" value={playerName} onChange={(e) => setPlayerName(e.target.value)} />
            </FormControl>
          </ModalBody>
          <ModalFooter justifyContent="center">
            <Button colorScheme="teal" mr={3} onClick={handleSaveChanges}>Tallenna</Button>
            <Button _hover={{ bg: 'terraCotta' }} bg="deepSlateBlue" color="white" onClick={() => { setPlayerName(''); onClose(); }}>Peruuta</Button>
          </ModalFooter>
          {selectedPlayer && (
            <Flex justifyContent="flex-start" width="100%" pt={4} mb={4}>
              <VStack spacing={2} align="start" pl={6}>
                {renderNicknameHistory(selectedPlayer.nickname_history)}
              </VStack>
            </Flex>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default Players;
