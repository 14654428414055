// src/theme.js
import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  colors: {
    antiqueWhite: '#F4F1DE',
    terraCotta: '#E07A5F',
    deepSlateBlue: '#3D405B',
    mutedSageGreen: '#81B29A',
    mutedSageGreenDark: '#679688',
  },
  styles: {
    global: {
      body: {
        bg: 'antiqueWhite', // Käytetään deep slate blue taustavärinä
        color: 'terraCotta', // Käytetään antique white tekstin värinä
      },
    },
  },
});

export default theme;
