import React, { useState, useEffect } from 'react';
import { Input, Box, Image, FormControl, FormLabel } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

function LandingPage({ onAuthenticate }) {
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (onAuthenticate(password)) {
      navigate('/dashboard');
    }
  }, [password, navigate, onAuthenticate]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="70vh"
    >
      <Box as="form" display="flex" flexDirection="column" alignItems="center" bg="mutedSageGreenDark" boxShadow="md" borderRadius="xl" p="7" mt="4">
        <Image
          src="/logo.png" // Logon polku
          mb="0" // Lisää marginaali logon ja tekstikentän välille
          boxSize="300px" // Säädä kokoa tarpeen mukaan
        />
        <FormControl display="flex" flexDirection="column" alignItems="center">
          <FormLabel htmlFor="password" srOnly>Koodi</FormLabel>
          <Input
            id="password"
            placeholder="Koodi"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            mb="2"
            _placeholder={{ color: 'gray.100' }} // Muuttaa placeholder-tekstin väriä
            width="200px"
          />
        </FormControl>
        {/* Ei nappia, automaattinen siirtyminen onAuthenticate-funktion avulla */}
      </Box>
    </Box>
  );
}

export default LandingPage;
