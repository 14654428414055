import React, { useState } from 'react';
import './App.css';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPage from './pages/LandingPage/LandingPage';
import Dashboard from './pages/Dashboard/Dashboard';
import MatchAddition from './pages/MatchAddition/Match';
import Layout from './pages/Layout/Layout';
import ProtectedRoute from './routing/ProtectedRoute';
import Players from './pages/Players/Players';
import theme from './theme';


function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleAuthentication = (password) => {
    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, '0');
    const hashedPassword = "nhlst" + day;
    
    const isValid = password === hashedPassword;
    setIsAuthenticated(isValid);
    return isValid; // Palauta boolean-arvo
  };
  
  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage onAuthenticate={handleAuthentication} />} />
          <Route path="/dashboard" element={<ProtectedRoute isAuthenticated={isAuthenticated}><Layout><Dashboard /></Layout></ProtectedRoute>} />
          <Route path="/match" element={<ProtectedRoute isAuthenticated={isAuthenticated}><Layout><MatchAddition /></Layout></ProtectedRoute>} />
          <Route path="/players" element={<ProtectedRoute isAuthenticated={isAuthenticated}><Layout><Players /></Layout></ProtectedRoute>} />
        </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;
