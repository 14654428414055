// src/services/sortUtils.js tai src/utils/sortUtils.js

/**
 * Järjestää annetun taulukon objekteja tietyllä kentällä.
 * @param {Array} array - Taulukko, jota järjestetään.
 * @param {string} field - Kentän nimi, jonka mukaan järjestetään.
 * @param {string} direction - Järjestämissuunta, 'ASC' tai 'DESC'.
 * @returns {Array} - Järjestetty taulukko.
 */
export const sortArrayByField = (array, field, direction = 'ASC') => {
    return [...array].sort((a, b) => {
      if (a[field] < b[field]) return direction === 'ASC' ? -1 : 1;
      if (a[field] > b[field]) return direction === 'ASC' ? 1 : -1;
      return 0;
    });
  };
  
  