import React from 'react';
import { Box } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

const backgroundImages = {
  '/dashboard': 'backgroundphoto5.png',
  '/players': 'backgroundphoto3.png',
  '/match': 'backgroundphoto4.png',
  '/url': 'backgroundphoto8.png',
  '/url1': 'backgroundphoto6.png',
  '/url2': 'backgroundphoto7.png',
  '/url3': 'backgroundphoto1.png',
  '/url4': 'backgroundphoto2.png',
};

function AnimatedBackground() {
  const location = useLocation();
  const backgroundImage = backgroundImages[location.pathname] || 'defaultBackground.png'; // Oletustaustakuva

  return (
    <Box
      position="fixed"
      top="0"
      left="0"
      width="full"
      height="full"
      bgImage={`url(/${backgroundImage})`}
      bgSize="cover"
      bgPos="center"
      zIndex="-1"
    />
  );
}

export default AnimatedBackground;
