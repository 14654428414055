import React, { useState, useEffect, useMemo } from 'react';
import {
  Button, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader,
  ModalBody, ModalFooter, FormControl, FormLabel, Input, Select, Flex, Text, Stack, Box
} from '@chakra-ui/react';
import TableComponent from '../../components/TableComponent';
import AnimationWrapper from '../../components/AnimationWrapper';
import Loading from '../../components/Loading';
import axios from 'axios';
import { keyframes } from '@emotion/react';

const Match = () => {
  const [matches, setMatches] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedMatch, setSelectedMatch] = useState(null);

  const [homePlayer1, setHomePlayer1] = useState('');
  const [homePlayer2, setHomePlayer2] = useState('');
  const [awayPlayer1, setAwayPlayer1] = useState('');
  const [awayPlayer2, setAwayPlayer2] = useState('');
  const [homeTeamGoals, setHomeTeamGoals] = useState(0);
  const [awayTeamGoals, setAwayTeamGoals] = useState(0);
  const [overtime, setOvertime] = useState(false);
  const [played, setPlayed] = useState('');

  const [players, setPlayers] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Lisätty tila lataustilan seuraamiseksi

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState('');
  const [isInvalid, setIsInvalid] = useState(false);

  const shake = keyframes`
    0%, 100% { transform: translateX(0); }
    10%, 30%, 50%, 70%, 90% { transform: translateX(-1px); }
    20%, 40%, 60%, 80% { transform: translateX(1px); }
  `;

  useEffect(() => {
    fetchMatches();
  }, []);    

  useEffect(() => {
    setTimeout(() => {
      fetchPlayers();
    }, 400);
  }, []);

  const fetchPlayers = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/players`);
      setPlayers(response.data.players);
      setIsLoading(false); // Merkitse lataus valmiiksi tässä vaiheessa
    } catch (error) {
      console.error('Error fetching players:', error);
    }
  };

  const fetchMatches = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/matches`);
      const convertedMatches = response.data.matches.map(match => {
        return {
          ...match,
          played: new Date(match.played)
        };
      });
      setMatches(convertedMatches);
    } catch (error) {
      console.error('Error fetching matches:', error);
    }
  };

  const customOnClose = async () => {
    // Jos modal suljetaan, nollaa delete confirmation ja piilota syöttökenttä
    setShowDeleteConfirmation(false);
    setDeleteConfirmation('');
    onClose(); // Kutsu alkuperäistä onClose-toimintoa sulkeaksesi modalin
  };

  const handleAddMatch = () => {
    setSelectedMatch(null);
    setHomePlayer1('');
    setHomePlayer2('');
    setAwayPlayer1('');
    setAwayPlayer2('');
    setHomeTeamGoals(0);
    setAwayTeamGoals(0);
    setOvertime(false);
    const localDate = new Date();
    setPlayed(convertToLocalTime(localDate).toISOString().slice(0, 16));
    onOpen();
  };

  const handleEditMatch = (matchId) => {
    const matchToEdit = matches.find(match => match.id === matchId);
    if (matchToEdit) {
      setSelectedMatch(matchToEdit);
      
      setHomePlayer1(matchToEdit.homePlayer1?.name || '');
      setHomePlayer2(matchToEdit.homePlayer2?.name || '');
      setAwayPlayer1(matchToEdit.awayPlayer1?.name || '');
      setAwayPlayer2(matchToEdit.awayPlayer2?.name || '');
  
      setHomeTeamGoals(matchToEdit.homeTeamGoals);
      setAwayTeamGoals(matchToEdit.awayTeamGoals);
      setOvertime(matchToEdit.overtime);
      setPlayed(convertToLocalTime(new Date(matchToEdit.played)).toISOString().slice(0, 16));
      onOpen();
    }
  };    

  const handleSaveChanges = async () => {
    try {
      const payload = {
        homePlayer1: players.find(p => p.name === homePlayer1)?.id,
        awayPlayer1: players.find(p => p.name === awayPlayer1)?.id,
        homeTeamGoals,
        awayTeamGoals,
        overtime,
        played: new Date(played)
      };
      
      // Lisää homePlayer2 ja awayPlayer2 vain, jos ne on valittu
      if (homePlayer2) {
        payload.homePlayer2 = players.find(p => p.name === homePlayer2)?.id;
      }
      if (awayPlayer2) {
        payload.awayPlayer2 = players.find(p => p.name === awayPlayer2)?.id;
      }
  
      if (selectedMatch) {
        // Update existing match
        await axios.put(`${process.env.REACT_APP_API_URL}/matches/${selectedMatch.id}`, payload);
      } else {
        // Add new match
        await axios.post(`${process.env.REACT_APP_API_URL}/matches`, payload);
      }
      onClose();
      fetchMatches();
    } catch (error) {
      console.error('Error saving match:', error);
    }
  };

  const handleDeleteButtonClick = () => {
    setShowDeleteConfirmation(true); // Näytä syöttökenttä, kun käyttäjä painaa "Poista" nappia
    setIsInvalid(false); // Aseta virheellisyystila aluksi pois päältä
  };

  const handleConfirmDelete = async () => {
    if (deleteConfirmation.toLowerCase() === 'vahvistan') {
      try {
        // API-kutsu ottelun poistamiseksi käyttäen selectedMatch.id
        await axios.delete(`${process.env.REACT_APP_API_URL}/matches/${selectedMatch.id}`);
        customOnClose();
        fetchMatches(); // Hae ottelut uudelleen onnistuneen poiston jälkeen
      } catch (error) {
        console.error('Error deleting match:', error);
      }
    } else {
      setIsInvalid(true); // Aseta virheellisyystila päälle, jos syöte on väärä
    }
  };

  const formatDate = (date) => {
    if (!date) return '';
    return new Date(date).toLocaleDateString("fi-FI", {
      day: 'numeric', month: 'numeric', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit'
    });
  };

  const convertToLocalTime = (date) => {
    const localTime = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
    return localTime;
  };

  const sortedMatches = useMemo(() => {
    return [...matches].sort((a, b) => new Date(b.played) - new Date(a.played));
  }, [matches]);

  const columns = useMemo(() => [
    { Header: 'Pelattu', accessor: 'played', isDate: true },
    { Header: 'Pelaaja 1 (K)', accessor: d => d.homePlayer1?.name || '' },
    { Header: 'Pelaaja 2 (K)', accessor: d => d.homePlayer2?.name || '' },
    { Header: 'Koti', accessor: 'homeTeamGoals' },
    { Header: 'Vieras', accessor: 'awayTeamGoals' },
    { Header: 'Pelaaja 1 (V)', accessor: d => d.awayPlayer1?.name || '' },
    { Header: 'Pelaaja 2 (V)', accessor: d => d.awayPlayer2?.name || '' },
    { Header: 'Jatkoaika', accessor: 'overtime' }
  ], []);

  const tableOptions = {
    initialState: {
      sortBy: [{ id: 'played', desc: true }]
    }
  };

  return (
    <>
      {isLoading ? (
        <Loading message="Ladataan otteluita..." />
      ) : (
      <AnimationWrapper views={[
        <TableComponent
          title="Ottelut"
          data={sortedMatches}
          columns={columns}
          onEditRow={handleEditMatch}
          onAddRow={handleAddMatch}
          options={tableOptions}
        />
      ]} />
      )}
      <Modal isOpen={isOpen} onClose={customOnClose}>
        <ModalOverlay />
        <ModalContent bg='antiqueWhite'>
          <ModalHeader>
            <Flex justifyContent="space-between" alignItems="center">
              <Box>
                {selectedMatch ? 'Muokkaa ottelua' : 'Lisää ottelu'}
              </Box>
              {selectedMatch && (
                <Box textAlign="right" fontSize="10" fontStyle="italic" color='mutedSageGreenDark'>
                  <Text>Created: {formatDate(selectedMatch.created)}</Text>
                  <Text>Last Modified: {formatDate(selectedMatch.last_modified)}</Text>
                </Box>
              )}
            </Flex>
          </ModalHeader>
          <ModalBody>
            <FormControl mt={0}>
              <FormLabel>Pelattu</FormLabel>
              <Input type="datetime-local" value={played} onChange={(e) => setPlayed(e.target.value)} />
            </FormControl>
            <FormControl mt={6}>
              <FormLabel color="teal">Kotijoukkueen tiedot</FormLabel>
              <Select 
                placeholder="Valitse pelaaja" 
                value={homePlayer1} 
                onChange={(e) => setHomePlayer1(e.target.value)}
              >
                {players.filter(player => 
                  ![homePlayer2, awayPlayer1, awayPlayer2].includes(player.name)
                ).map((player) => (
                  <option key={player.id} value={player.name}>{player.name}</option>
                ))}
              </Select>
              <Select 
                mt={2} 
                placeholder="Valitse pelaaja" 
                value={homePlayer2} 
                onChange={(e) => setHomePlayer2(e.target.value)}
                isDisabled={!homePlayer1}
              >
                {players.filter(player => 
                  ![homePlayer1, awayPlayer1, awayPlayer2].includes(player.name)
                ).map((player) => (
                  <option key={player.id} value={player.name}>{player.name}</option>
                ))}
              </Select>
              <FormControl mt={4}>
                <FormLabel color="teal">Kotijoukkueen maalit</FormLabel>
                <Input type="number" value={homeTeamGoals} onChange={(e) => setHomeTeamGoals(e.target.value)} />
              </FormControl>
            </FormControl>
            <FormControl mt={6}>
              <FormLabel color="deepSlateBlue">Vierasjoukkueen tiedot</FormLabel>
              <Select 
                placeholder="Valitse pelaaja" 
                value={awayPlayer1} 
                onChange={(e) => setAwayPlayer1(e.target.value)}
              >
                {players.filter(player => 
                  ![homePlayer1, homePlayer2, awayPlayer2].includes(player.name)
                ).map((player) => (
                  <option key={player.id} value={player.name}>{player.name}</option>
                ))}
              </Select>
              <Select 
                mt={2} 
                placeholder="Valitse pelaaja" 
                value={awayPlayer2}
                isDisabled={!awayPlayer1}
                onChange={(e) => setAwayPlayer2(e.target.value)}
              >
                {players.filter(player => 
                  ![homePlayer1, homePlayer2, awayPlayer1].includes(player.name)
                ).map((player) => (
                  <option key={player.id} value={player.name}>{player.name}</option>
                ))}
              </Select>
              <FormControl mt={4}>
                <FormLabel color="deepSlateBlue">Vierasjoukkueen maalit</FormLabel>
                <Input type="number" value={awayTeamGoals} onChange={(e) => setAwayTeamGoals(e.target.value)} />
              </FormControl>
            </FormControl>
            <FormControl mt={6}>
              <FormLabel>Jatkoaika</FormLabel>
              <Select value={overtime} onChange={(e) => setOvertime(e.target.value === 'true')}>
                <option value='false'>Ei</option>
                <option value='true'>Kyllä</option>
              </Select>
            </FormControl>
          </ModalBody>
          <ModalFooter justifyContent="center">
            <Stack spacing={0} direction="column" align="center">
              <Stack direction="row" spacing={2}>
                <Button width="150px" mt={3} colorScheme="teal" onClick={handleSaveChanges}>Tallenna</Button>
                <Button width="150px" mt={3} _hover={{ bg: 'terraCotta' }} bg="deepSlateBlue" color="white" onClick={customOnClose}>Peruuta</Button>
              </Stack>
              {selectedMatch && (
                <Stack direction="row" spacing={0}>
                  {showDeleteConfirmation && (
                    <FormControl isInvalid={isInvalid} onAnimationEnd={() => setIsInvalid(false)}>
                      <Input
                        placeholder="Kirjoita 'vahvistan'"
                        value={deleteConfirmation}
                        onChange={(e) => setDeleteConfirmation(e.target.value)}
                        size="sm"
                        width="150px"
                        height="40px"
                        mt={3}
                        rounded={6}
                        animation={isInvalid ? `${shake} 1s` : 'none'} // Käytä heilahdusanimatiota, jos syöte on virheellinen
                      />
                    </FormControl>
                  )}
                  {showDeleteConfirmation ? (
                    <Button mt={3} width="160px" height="40px" colorScheme="red" onClick={handleConfirmDelete}>Vahvista</Button>
                  ) : (
                    <Button mt={3} width="160px" height="40px" colorScheme="red" onClick={handleDeleteButtonClick}>Poista</Button>
                  )}
                </Stack>
              )}
            </Stack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Match;
