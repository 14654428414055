import React from 'react';
import { Box, useBreakpointValue } from '@chakra-ui/react';
import NavigationDrawer from './NavigationDrawer';
import AnimatedBackground from '../../components/AnimatedBackground';

function Layout({ children }) {
  // Määritä marginLeft arvo dynaamisesti perustuen breakpointiin.
  // Oletetaan, että NavigationDrawer on piilotettu pienemmillä näytöillä ja näkyvissä vain md-breakpointista ylöspäin.
  const marginLeft = useBreakpointValue({ base: "0", md: "250px" });

  // Lasketaan sisällön leveys dynaamisesti vähentämällä NavigationDrawerin leveys näytön leveydestä.
  // Tämä varmistaa, että sisältö ei ylitä näytön leveyttä.
  const contentWidth = `calc(100% - ${marginLeft})`;

  return (
    <Box position="relative" minHeight="100vh">
      <AnimatedBackground />
      <Box display="flex" overflowX="hidden">
        <NavigationDrawer />
        <Box flex="1" p="12"
             width={contentWidth} // Aseta sisällön leveys dynaamisesti
             minHeight="100vh"
             overflowX="hidden" // Estä sisällön vuotaminen yli vaakasuunnassa
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
}

export default Layout;
