import React from 'react';
import { Flex, Spinner, Text} from '@chakra-ui/react';

const Loading = ({ message }) => {
  return (
    <Flex justifyContent="center" alignItems="center" p="4" bg="antiqueWhite" boxShadow="md" borderRadius="lg">
        <Spinner
            thickness="4px"
            speed="0.8s"
            emptyColor="gray.200"
            color="teal.500"
            size="xl"
        />
        <Text fontSize="xl" ml={4}>
            {message}
        </Text>
    </Flex>
  );
};

export default Loading;
