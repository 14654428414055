import React, { useState } from 'react';
import {
  Box, Text, Table, Thead, Tbody, Tr, Th, Td, IconButton, Flex, ButtonGroup, Button
} from '@chakra-ui/react';
import { TriangleDownIcon, TriangleUpIcon, EditIcon, AddIcon, ChevronLeftIcon, ChevronRightIcon, DownloadIcon } from '@chakra-ui/icons';
import { sortArrayByField } from '../services/sortUtils';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

const TableComponent = ({ title, data, columns, onAddRow, onEditRow }) => {
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState('ASC');
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute:'numeric' };
    return new Date(dateString).toLocaleDateString("fi-FI", options);
  };

  const handleSort = (field) => {
    if (sortField === field) {
      setSortOrder(sortOrder === 'ASC' ? 'DESC' : 'ASC');
    } else {
      setSortField(field);
      setSortOrder('ASC');
    }
  };

  const sortedData = sortField ? sortArrayByField(data, sortField, sortOrder) : data;

  // Sivutuksen logiikka
  const lastRowIndex = currentPage * rowsPerPage;
  const firstRowIndex = lastRowIndex - rowsPerPage;
  const currentRows = sortedData.slice(firstRowIndex, lastRowIndex);

  const nextPage = () => setCurrentPage(prev => prev + 1);
  const prevPage = () => setCurrentPage(prev => prev - 1);

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data.map(row => {
      const newRow = {};
      columns.forEach(column => {
        const value = typeof column.accessor === 'function' ? column.accessor(row) : row[column.accessor];
        newRow[column.Header] = typeof value === 'boolean' ? (value ? 'Kyllä' : 'Ei') : value;
      });
      return newRow;
    }));
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});
    saveAs(blob, `${title}.xlsx`);
  };  

  return (
    <Box bg="antiqueWhite" boxShadow="md" borderRadius="lg" p={4}>
      <Flex justifyContent="center" alignItems="center" mb={4}>
        <Text fontSize="xl" fontWeight="bold" mb={4} textAlign="center">{title}</Text>
        {onAddRow && (
          <IconButton icon={<AddIcon />} size="sm" variant="outline" colorScheme="teal" onClick={onAddRow} mb={4} ml={3}/>
        )}
        <IconButton icon={<DownloadIcon />} size="sm" variant="outline" colorScheme="teal" onClick={exportToExcel} mb={4} ml={3} />
      </Flex>
      <Box className="scrollWrapper">
        <Table variant="simple" className="myCustomTable">
          <Thead>
            <Tr>
              {onEditRow && <Th>Muokkaa</Th>}
              {columns.map(column => (
                <Th key={column.accessor} cursor="pointer" onClick={() => handleSort(column.accessor)} position="relative">
                  {column.Header}
                  {sortField === column.accessor && (
                    <Box as="span" position="absolute" top="50%" transform="translateY(-50%)">
                      {sortOrder === 'ASC' ? <TriangleDownIcon ml={2} /> : <TriangleUpIcon ml={2} />}
                    </Box>
                  )}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody fontSize="13px">
            {currentRows.map((row, index) => (
              <Tr key={row.id} sx={{ '& > td': { py: 1.5 } }}> {/* pienennä padding-y (ylä- ja alapuoli) */}
                {onEditRow && (
                  <Td>
                    <IconButton
                      icon={<EditIcon />}
                      size="xs"
                      color="white"
                      _hover={{ bg: 'deepSlateBlue' }}
                      bg="terraCotta"
                      onClick={() => onEditRow(row.id)}
                    />
                  </Td>
                )}
                {columns.map(column => (
                  <Td key={`${row.id}-${column.Header}`} sx={{ py: 1 }}> {/* Huomaa, että käytin tässä column.Header, koska column.accessor voi olla funktio */}
                    {
                      column.isDate
                        ? formatDate(row[column.accessor])
                        : typeof column.accessor === 'function'
                          ? column.accessor(row)
                          : (typeof row[column.accessor] === 'boolean'
                              ? (row[column.accessor] ? 'Kyllä' : 'Ei')
                              : row[column.accessor])
                    }
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
      {data.length > rowsPerPage && (
        <Flex justifyContent="center" mt={4}>
          <ButtonGroup isAttached variant="outline">
            <Button onClick={prevPage} isDisabled={currentPage <= 1} borderColor="teal.500" _hover={{ borderColor: "teal.700" }}>
              <ChevronLeftIcon color="terraCotta"/>
            </Button>
            <Button onClick={nextPage} isDisabled={currentPage * rowsPerPage >= data.length} borderColor="teal.500" _hover={{ borderColor: "teal.700" }}>
              <ChevronRightIcon color="terraCotta"/>
            </Button>
          </ButtonGroup>
        </Flex>
      )}
    </Box>
  );
};

export default TableComponent;
