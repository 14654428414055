import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

export const LineChartComponent = ({ title, data }) => {
  const playerNames = data.length > 0 ? Object.keys(data[0]).filter(key => key !== 'date') : [];

  // Määrittele väripaletti. Voit laajentaa tätä listaa tarpeen mukaan.
  const colors = ['#8884d8', '#82ca9d', '#ffc658', '#ff8042', '#00c49f', '#ffbb28', '#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  return (
    <Flex justifyContent="center" alignItems="center" p="4" bg="antiqueWhite" boxShadow="md" borderRadius="lg">
      <Box width="100%">
        <Text fontSize="xl" fontWeight="bold" mb={4} textAlign="center">{title}</Text>
        <ResponsiveContainer width="100%" height={400}>
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            {playerNames.map((name, index) => (
              <Line key={name} type="monotone" dataKey={name} stroke={colors[index % colors.length]} activeDot={{ r: 8 }} />
            ))}
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </Flex>
  );
};
