import React, { useState, useEffect } from 'react';
import { CircularProgress, CircularProgressLabel, Flex } from '@chakra-ui/react';

const CountdownToWeekday = () => {
  const getTargetTime = () => {
    let now = new Date();
    let target = new Date();

    // Asetetaan tavoiteaika seuraavaksi arkipäiväksi klo 17:00
    target.setHours(17, 0, 0, 0); // Asetetaan tavoiteaika klo 17:00

    // Jos on viikonloppu tai kello on yli 17, siirrytään seuraavaan arkipäivään
    if (now.getHours() >= 17 || now.getDay() === 6 || now.getDay() === 0) {
      target.setDate(target.getDate() + ((1 + 7 - target.getDay()) % 7 || 7)); // Seuraava maanantai
    }

    return target;
  };

  const calculateTimeLeft = () => {
    const now = new Date();
    const target = getTargetTime();

    const difference = target - now;
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const getProgressValue = (value, max) => (value / max) * 100;

  return (
    <Flex direction="column" align="center" justify="center" h="10vh" marginTop='5' marginLeft='2' marginRight='2'>
      <Flex>
        <CircularProgress value={getProgressValue(timeLeft.hours, 24)} color="deepSlateBlue">
          <CircularProgressLabel>{String(timeLeft.hours).padStart(2, '0')}</CircularProgressLabel>
        </CircularProgress>
        <CircularProgress value={getProgressValue(timeLeft.minutes, 60)} color="mutedSageGreen" mx={2}>
          <CircularProgressLabel>{String(timeLeft.minutes).padStart(2, '0')}</CircularProgressLabel>
        </CircularProgress>
        <CircularProgress value={getProgressValue(timeLeft.seconds, 60)} color="terraCotta">
          <CircularProgressLabel>{String(timeLeft.seconds).padStart(2, '0')}</CircularProgressLabel>
        </CircularProgress>
      </Flex>
    </Flex>
  );
};

export default CountdownToWeekday;
