import React, { useState, useRef, useEffect } from 'react';
import { Box, IconButton } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import gsap from 'gsap';

const AnimationWrapper = ({ views }) => {
  const [viewIndex, setViewIndex] = useState(0);
  const animationContainerRef = useRef();

  // Animaatio kun näkymä vaihtuu
  useEffect(() => {
    gsap.fromTo(animationContainerRef.current, { opacity: 0, x: -100 }, { opacity: 1, x: 0, duration: 1 });
  }, [viewIndex]);

  // Seuraavan näkymän käsittelijä
  const handleNextView = () => {
    const nextIndex = (viewIndex + 1) % views.length;
    setViewIndex(nextIndex);
  };

  // Edellisen näkymän käsittelijä
  const handlePrevView = () => {
    const prevIndex = (viewIndex - 1 + views.length) % views.length;
    setViewIndex(prevIndex);
  };

  return (
      <Box w="full" direction="column" align="center" position="relative" ref={animationContainerRef}>
        {views.length > 1 && (
          <>
            <IconButton
              icon={<ChevronLeftIcon boxSize="4" />}
              onClick={handlePrevView}
              position="absolute"
              left="15px"
              zIndex="overlay"
              size="sm"
              top="15px"
              bg="terraCotta"
              _hover={{ bg: 'mutedSageGreenDark' }}
            />
            <IconButton
              icon={<ChevronRightIcon boxSize="4" />}
              onClick={handleNextView}
              position="absolute"
              right="15px"
              zIndex="overlay"
              size="sm"
              top="15px"
              bg="terraCotta"
              _hover={{ bg: 'mutedSageGreenDark' }}
            />
          </>
        )}
        {views[viewIndex]}
      </Box>
  );
};

export default AnimationWrapper;
