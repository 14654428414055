import React from 'react';
import {
  Box, Flex, Text
} from '@chakra-ui/react';
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
  } from 'recharts';
  

export const BarChartComponent = ({ title, data }) => {
  return (
    <Flex justifyContent="center" alignItems="center" p="4" bg="antiqueWhite" boxShadow="md" borderRadius="lg">
      <Box width="100%">
        <Text fontSize="xl" fontWeight="bold" mb={4} textAlign="center">{title}</Text>
        <ResponsiveContainer width="100%" height={400}>
          <BarChart data={data} margin={{ top: 5, right: 0, left: 0, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" stroke="#E07A5F" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="points" fill="#3D405B" barSize={70}/>
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </Flex>
  );
};
